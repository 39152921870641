.login {
  color: #fff;
  background: center top / cover
    url("https://cdn.workmob.com/stories_workmob/images/common/promo-banner.jpg");
  position: fixed;
  z-index: 3;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.login::before {
  content: '';
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.loginForm {
  max-width: 550px;
  margin: 2em auto 0;
  display: flex;
  flex-direction: column;
  padding: 0 1em;
  /* align-items: center; */
}

.inputLabel {
  text-align: start;
  margin-bottom: 1.5em;
}

.formInput {
  font-size: 0.9em;
  width: 100%;
  height: 2.5em;
  padding: 0.1em 0.5em;
  border: 1px solid #ced4da;
  border-radius: 0.25em;
  margin-top: 0.5em;
  display: block;
  box-sizing: border-box;
}

.submitButton {
  width: max-content;
  color: inherit;
  background: #007bff;
  padding: 0.6em 1.1em;
  border: 1px solid #007bff;
  border-radius: 0.25em;
  margin-top: 0.5em;
  cursor: pointer;
  transition: background-color linear 100ms;
}

.submitButton:hover {
  background: #0060c7;
}
